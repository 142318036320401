import { useState } from 'react';
import { logoArka } from '../assets';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <nav className="bg-bgNavbar shadow-md fixed w-full z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <img
              className="h-6 sm:h-7 md:h-8 lg:h-10 w-auto"
              src={logoArka}
              alt="Company Logo"
            />
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              type="button"
              className="block text-white hover:text-gray-200 focus:text-gray-200 focus:outline-none"
            >
              <svg
                className="h-6 w-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 12a1 1 0 0 1-1 1H6a1 1 0 1 1 0-2h12a1 1 0 0 1 1 1zm-8-5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1zm-6 10a1 1 0 1 1 0-2h12a1 1 0 1 1 0 2H5z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 6a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1zm0 7a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1z"
                  />
                )}
              </svg>
            </button>
          </div>
          <div className="hidden md:flex space-x-4">
            <a href="#" className="text-white hover:text-gray-200 transition duration-300">
              Home
            </a>
            <a href="#" className="text-white hover:text-gray-200 transition duration-300">
              DevLog
            </a>
            <a href="#" className="text-white hover:text-gray-200 transition duration-300">
              Contact Me
            </a>
          </div>
        </div>
      </div>
      {/* Mobile sidebar */}
      <div
  className={`md:hidden bg-bgNavbar2 w-64 fixed top-16 left-0 bottom-0 z-20 transform transition-transform duration-300 ${
    isOpen ? 'translate-x-0' : '-translate-x-full'
  }`}
>
  <div className="flex flex-col items-start py-4 px-6 h-full">
          <a href="#" className="text-white hover:text-gray-200 py-3">
            Home
          </a>
          <a href="#" className="text-white hover:text-gray-200 py-3">
            DevLog
          </a>
          <a href="#" className="text-white hover:text-gray-200 py-3">
            Contact Me
          </a>
        </div>
      </div>
      
    </nav>
    </>
  );
};

export default Navbar;
