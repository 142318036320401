import Navbar from "../components/Navbar";
import Jumbotron from "../components/Jumbotron";

function Home() {
    return (
        <div>
            <Navbar />
            <Jumbotron />
        </ div>
    );
}

export default Home;
