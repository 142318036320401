import { Link } from "react-router-dom";

function Devlog() {
    return (
        <div>
            <h1>This is the Devlog page</h1>
        </div>
    );
}

export default Devlog;
