import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

const Jumbotron = () => (
    <div className="jumbotron relative flex items-center justify-center text-center bg-cover bg-center h-screen bg-jumbotron" >
      <div className="bg-black bg-opacity-50 p-6 rounded-lg">
        <h2 className="text-4xl md:text-6xl font-bold text-white mb-4">THIS SITE IS UNDER CONSTRUCTION</h2>
        <p className="text-lg md:text-2xl text-white mb-8">Watch anywhere. Cancel anytime.</p>
        <p className="text-lg md:text-xl text-white mb-4">Want to know first ? Enter your email, but it doesnt work, LOL.</p>
        <form className="flex flex-col md:flex-row justify-center">
          <input type="email" placeholder="Email address" required className="p-3 w-full md:w-96 border border-gray-300 rounded-md md:rounded-l-md mb-2 md:mb-0" />
          <button type="submit" className="p-3 bg-red-600 text-white rounded-md md:rounded-r-md hover:bg-red-700 ml-2"><FontAwesomeIcon icon={faCoffee} /> Notify Me</button>
        </form>
      </div>
    </div>
  );

  export default Jumbotron;