import logo from './logo.svg';
import { Routes, Route } from "react-router-dom"
import './App.css';
import Home from "./pages/Home"
import Devlog from "./pages/Devlog"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="devlog" element={<Devlog />} />
      </Routes>
    </div>
  );
}

export default App;
